<template>
  <div class="cookie-banner">
    <div class="banner-container" role="region" aria-label="Cookie Banner">
      <div class="message-container">
        <p class="message-text">
          {{ message }}
        </p>
        <a :href="cookieUrl">
          {{ urlLabel }}
          <Icon name="cta-arrow" :color="$config.colors.gold" />
        </a>
      </div>
      <div :class="['options-container', { 'button-outline': !isMobileView }]">
        <Button class="secondary" @click.native="permitCookies(false)">Decline</Button>
        <Button class="primary" autofocus @click.native="permitCookies(true)">Allow Cookies</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { likeshopCopy } from '@/config/messages';
import { browserStorageSetItem, getCookieConsentKey } from '@/utils/browserStorage';
import { isMobileClient } from '@/utils/mobile';

import Button from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';

export default {
  components: {
    Button,
    Icon,
  },
  props: {
    platformType: {
      type: String,
      required: true,
    },
    socialMediaAccountName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: likeshopCopy.cookiePolicyMessage,
      urlLabel: likeshopCopy.cookiePolicyUrlLabel,
      cookieUrl: 'https://dashsocial.com/cookies/',
    };
  },
  computed: {
    isMobileView() {
      return isMobileClient();
    },
  },
  methods: {
    permitCookies(val) {
      const key = getCookieConsentKey(this.platformType, this.socialMediaAccountName);

      if (key) {
        browserStorageSetItem(key, val);
        this.$emit('allowCookies', val);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.cookie-banner {
  background-color: white;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: z-index('raised');

  .banner-container {
    margin: 0 auto;

    .message-container {
      font-size: $x12;
      line-height: 15px;

      .message-text {
        display: inline;
      }

      a {
        color: $header-text-black;
        text-decoration: underline;
      }
    }

    .options-container {
      display: flex;
      justify-content: center;
      margin-bottom: $space-16;

      button {
        background: $white;
        border-radius: 1rem;
        font-size: $x12;
        font-weight: 600;
        height: 26px;
        margin: 0 $space-4;
        padding: 0;
        width: 120px;

        &.primary {
          border: 1.6px solid $gold;
          color: $gold;
        }

        &.secondary {
          border: 1.6px solid $secondary-text-icon;
          color: $secondary-text-icon;
        }
      }

      &.button-outline button {
        &:focus {
          box-shadow: 0 0 3px 2px $link;
          outline: none;
        }
      }
    }
  }
}

@include media('<=mobile-landscape') {
  .cookie-banner {
    justify-content: center;

    .message-container {
      margin: $space-16 auto;
      max-width: 300px;
      text-align: center;

      ::v-deep .icon {
        display: none;
      }
    }
  }
}

@include media('>mobile-landscape') {
  .cookie-banner {
    display: flex;
    height: 4rem;

    .banner-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      max-width: 916px;
      width: 100%;

      .message-container {
        display: flex;
        font-size: $x14;
        margin-left: $space-16;

        a {
          align-items: center;
          color: $gold;
          display: flex;
          margin-left: $space-8;
          text-decoration: none;

          ::v-deep .icon {
            margin-left: 12px;
          }
        }
      }

      .options-container {
        margin: 0 $space-16 0 0;

        button {
          font-size: $x14;
          height: 32px;

          &.secondary {
            border: none;
            width: 64px;
          }
        }
      }
    }
  }
}
</style>
